import $ from "jquery";
import gsap from "gsap";

export const barbaInitialLoad = () => {
  const nav = $("#mv-nav");

  // 初回ロード時にhome以外の場合
  const barbaNamespace = document.body.getAttribute("data-page");
  if (barbaNamespace !== "home") {
    const navCtx = gsap.timeline();
    navCtx.to(nav, {
      opacity: 0,
      duration: 0,
      ease: "power2.in",
    });

    document.body.setAttribute("data-animation-type", "fade");
  }
};
