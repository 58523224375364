import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export const worksInfiniteSlider = {
  ctx: null,
  init() {
    this.ctx = gsap.context(() => {
      const scrollEls = gsap.utils.toArray(".js-works-infinite-slider");
      scrollEls.forEach((scrollEl, i) => {
        const targetEls = scrollEl.querySelectorAll(
          ".js-works-infinite-slider-col"
        );

        if (i % 2 === 0) {
          let tween = gsap.to(targetEls, {
            yPercent: -100,
            repeat: -1,
            duration: 30,
            ease: "linear",
          });
          // .totalProgress(0.5);

          gsap.set(scrollEl, { yPercent: 0 });
        } else {
          let tween = gsap.to(targetEls, {
            yPercent: 100,
            repeat: -1,
            duration: 30,
            ease: "linear",
          });
          // .totalProgress(0.5);

          gsap.set(scrollEl, { yPercent: -240 });
        }
      });
    });
  },
  destroy() {
    this.ctx.revert();
  },
};

// export const worksInfiniteSlider = () => {

//   const scrollEls = gsap.utils.toArray(".js-works-infinite-slider");
//   scrollEls.forEach((scrollEl, i) => {
//     const targetEls = scrollEl.querySelectorAll(
//       ".js-works-infinite-slider-col"
//     );

//     if (i % 2 === 0) {
//       let tween = gsap.to(targetEls, {
//         yPercent: -100,
//         repeat: -1,
//         duration: 15,
//         ease: "linear",
//       });
//       // .totalProgress(0.5);

//       gsap.set(scrollEl, { yPercent: 0 });
//     } else {
//       let tween = gsap.to(targetEls, {
//         yPercent: 100,
//         repeat: -1,
//         duration: 15,
//         ease: "linear",
//       });
//       // .totalProgress(0.5);

//       gsap.set(scrollEl, { yPercent: -240 });
//     }
//   });
// };
