import $ from "jquery";

export const loading = () => {
  if (document.querySelector(".front-page") !== null) {
    const firstVisitFlag = sessionStorage.getItem("first-visit");

    if (!firstVisitFlag) {
      showLoadingAnimation();

      sessionStorage.setItem("first-visit", true);
    }

    function showLoadingAnimation() {
      $("body").addClass("is-loading");
      setTimeout(() => {
        $("body").addClass("is-loading-done");
      }, 3000);
    }
  }
};
