export const playVideo = () => {
  // 動画を再生
  const vids = document.querySelectorAll("video");
  vids.forEach((vid) => {
    const playPromise = vid.play();
    if (playPromise !== undefined) {
      playPromise.then((_) => {}).catch((error) => {});
    }
  });
};
