import $ from "jquery";
import { isMobile } from "./isMobile";

export const handleScroll = () => {
  const anchors = document.querySelectorAll(".js-sidebar-hover");

  var array = {};

  anchors.forEach(function (elem, idx) {
    let name = elem
      .querySelector(".home-sidebar__link")
      .getAttribute("data-target");
    array[idx] = name;
  });

  const header = document.querySelector("#header");

  const arrayLength = Object.keys(array).length - 1;

  let scrollBuffer; // 判定を500px分早める
  const viewport = window.innerHeight;
  const triggerByViewport = (viewport / 3) * 2;

  if (isMobile()) {
    scrollBuffer = 250;
  } else {
    scrollBuffer = triggerByViewport;
  }

  var scroll = window.scrollY;
  let headerHeight = header.offsetHeight;

  for (var i = 0; i <= arrayLength; i++) {
    let targetEl = document.querySelector(array[i]);
    let rect = targetEl.getBoundingClientRect();
    let scrollTop = window.scrollY || document.documentElement.scrollTop;
    let myTop = Math.floor(rect.top + scrollTop) - headerHeight;

    // 最後の時
    if (i == arrayLength) {
      if (scroll + scrollBuffer >= myTop) {
        anchors.forEach((anchor) => {
          anchor.classList.remove("is-visible");
        });
        anchors[arrayLength].classList.add("is-visible");
      }
    }
    // 最後以外
    else {
      let nextTargetEl = document.querySelector(array[i + 1]);
      let nextRect = nextTargetEl.getBoundingClientRect();
      let nextScrollTop = window.scrollY || document.documentElement.scrollTop;
      let nextMyTop = nextRect.top + nextScrollTop - headerHeight;

      let firstTargetEl = document.querySelector(array[0]);
      let firstRect = firstTargetEl.getBoundingClientRect();
      let firstScrollTop = window.scrollY || document.documentElement.scrollTop;
      let firstMyTop = firstRect.top + firstScrollTop - headerHeight - 1;

      if (scroll + scrollBuffer <= firstMyTop) {
        anchors.forEach((anchor) => {
          if (anchor.classList.contains("is-visible")) {
            anchor.classList.remove("is-visible");
          }
        });
      }

      if (scroll + scrollBuffer >= myTop && scroll + scrollBuffer < nextMyTop) {
        anchors.forEach((anchor) => {
          if (anchor.classList.contains("is-visible")) {
            anchor.classList.remove("is-visible");
          }
        });
        anchors[i].classList.add("is-visible");
      }
    }
  }
};

export const handleSidebarClick = () => {
  const anchors = document.querySelectorAll(".js-sidebar-hover");

  anchors.forEach((anchor) => {
    const anchorLink = anchor.querySelector(".home-sidebar__link");
    anchorLink.addEventListener("click", function (e) {
      e.preventDefault();
      const target = this.getAttribute("data-target");
      const targetEl = document.querySelector(target);
      const header = document.querySelector("#header");
      const headerHeight = header.offsetHeight;
      const rect = targetEl.getBoundingClientRect();
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      const myTop = Math.floor(rect.top + scrollTop) - headerHeight;

      window.scrollTo({
        top: myTop,
        behavior: "smooth",
      });
    });
  });
};
