import $ from "jquery";
import barba from "@barba/core";
import gsap from "gsap";

export const barbaAfter = () => {
  barba.hooks.after((data) => {
    const ctt = data.next.container;
    const tl = gsap.timeline();

    if (document.body.getAttribute("data-animation-type") === "fade") {
      tl.to(ctt, {
        opacity: 1,
        // x: 0,
        duration: 0.6,
        delay: 0.5,
        ease: "power2.in",
      });
    } else {
      tl.to(ctt, {
        opacity: 1,
        duration: 0.3,
        delay: 0.5,
        ease: "power2.in",
      });
    }

    setTimeout(() => {
      document.body.classList.remove("is-transitioning");
    }, 1000);

    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }, 300);
  });
};
