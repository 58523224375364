import gsap from "gsap";

export const clipTextScroll = {
  // const mask = document.querySelector(".mask-pc");

  ctx: null,
  init() {
    this.ctx = gsap.context(() => {
      gsap.fromTo(
        ".js-scroll-text",
        {
          // x: "-75%",
          x: -1000,
          ease: "power1.in",
        },
        {
          // x: "-50%",
          x: 0,
          scrollTrigger: {
            trigger: ".js-scroll-text-wrapper",
            start: "top bottom",
            end: "bottom top",
            scrub: 2,
            onUpdate() {
              // console.log("onupdate");
              // mask.style.clipPath = "none";
              // // force the browser to recalculate the element
              // mask.offsetWidth;
              // mask.style.clipPath = "url(#svgPath)";
            },
          },
        }
      );
    });
  },
  destroy() {
    this.ctx.revert();
  },
};
