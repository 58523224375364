import $ from "jquery";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

export const mvScroll = {
  ctx: null,
  init() {
    this.ctx = gsap.context(() => {
      const mvLinks = document.querySelectorAll("#mv-nav a");
      const sections = document.querySelectorAll(".js-snap-scroll-section");
      const scrollDown = document.getElementById("mv-scroll-down");
      const homeBody = document.getElementById("home-body");

      const scrolling = {
        enabled: true,
        // events: "scroll,wheel,touchmove,pointermove".split(","),
        events: "scroll".split(","),
        prevent: (e) => e.preventDefault(),
        disable() {
          if (scrolling.enabled) {
            scrolling.enabled = false;
            window.addEventListener("scroll", gsap.ticker.tick, {
              passive: true,
            });
            scrolling.events.forEach((e, i) =>
              (i ? document : window).addEventListener(e, scrolling.prevent, {
                passive: false,
              })
            );
          }
        },
        enable() {
          if (!scrolling.enabled) {
            scrolling.enabled = true;
            window.removeEventListener("scroll", gsap.ticker.tick);
            scrolling.events.forEach((e, i) =>
              (i ? document : window).removeEventListener(e, scrolling.prevent)
            );
          }
        },
      };

      function goToSection(section, anim, i) {
        if (scrolling.enabled) {
          scrolling.disable();
          gsap.to(window, {
            scrollTo: { y: section, autoKill: false },
            onComplete: scrolling.enable,
            duration: 0.6,
          });

          anim && anim.restart();
        }
      }

      sections.forEach((section, i) => {
        if (i === 0) {
          ScrollTrigger.create({
            trigger: section,
            start: "top bottom-=1",
            end: "bottom top+=1",
            onEnter: () => goToSection(section),
            onEnterBack: () => goToSection(section),
          });
        } else {
          ScrollTrigger.create({
            trigger: section,
            start: "top bottom-=1",
            end: "bottom top+=1",
            onEnter: () => goToSection(section),
            onEnterBack: () => goToSection(section),
          });
        }
      });

      // mvのリンクをクリック時
      // MVから下にスクロールしたあと、上スクロールでMVに戻ってからリンクを押すと、なぜかgoToSectionが発火しながらページ遷移するのを防ぐ
      mvLinks.forEach((link) => {
        link.addEventListener("click", function () {
          goToSection(sections[0]);
        });
      });

      // SCROLL DOWNをクリック時
      scrollDown.addEventListener("click", () => {
        goToSection(homeBody);
      });
    });
  },
  destroy() {
    this.ctx.revert();
  },
};
