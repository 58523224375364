import barba from "@barba/core";

export const barbaAfterEnter = () => {
  barba.hooks.afterEnter((data) => {
    // setTimeout(() => {
    //   window.scrollTo({
    //     top: 0,
    //     left: 0,
    //     behavior: "smooth",
    //   });
    // }, 100);
  });
};
