import { Splide } from "@splidejs/splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";

export const worksSlider = () => {
  if (document.querySelector(".js-works-slider") !== null) {
    const splide = new Splide(".js-works-slider", {
      type: "loop",
      gap: "4rem",
      perMove: 1,
      autoWidth: true,
      arrows: false,
      drag: false,
      pagination: false,
      autoScroll: {
        pauseOnHover: false,
      },
      breakpoints: {
        768: {
          gap: "2.4rem",
        },
      },
    });

    splide.mount({ AutoScroll });
  }
};
