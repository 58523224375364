import $ from "jquery";
import gsap from "gsap";
import barba from "@barba/core";

export const barbaAfterLeave = () => {
  barba.hooks.afterLeave((data) => {
    const nextName = data.next.namespace;
    const ctt = data.current.container;
    const nav = $("#mv-nav");

    if (document.body.getAttribute("data-animation-type") === "fade") {
      const tl = gsap.timeline({
        onComplete() {
          document.body.setAttribute("data-page", nextName);

          const barbaNamespace = document.body.getAttribute("data-page");
          if (barbaNamespace !== "home") {
            setTimeout(() => {
              document.body.setAttribute("data-animation-type", "fade");
            }, 2000);
          }

          if (data.next.namespace !== "home") {
            const navCtx = gsap.timeline();
            navCtx.to(nav, {
              opacity: 0,
              duration: 0.3,
              ease: "power2.in",
            });
          }

          gsap.set(data.next.container, {
            opacity: 0,
            // x: 1000,
            duration: 0.3,
            ease: "power2.in",
          });
        },
      });
    } else {
      const tl = gsap.timeline({
        onComplete() {
          document.body.setAttribute("data-page", nextName);

          const barbaNamespace = document.body.getAttribute("data-page");
          if (barbaNamespace !== "home") {
            setTimeout(() => {
              document.body.setAttribute("data-animation-type", "fade");
            }, 2000);
          }
          gsap.set(data.next.container, {
            opacity: 0,
            // x: 1000,
            duration: 0.3,
            ease: "power2.in",
          });
        },
      });
    }

    // return tl.to(ctt, {
    //   opacity: 0,
    //   duration: 0.3,
    //   ease: "power2.in",
    // });
  });
};
