import gsap from "gsap";
import $ from "jquery";
import { mvScroll } from "../../mvScroll";
import { isMobile } from "../../isMobile";
import { handleScroll } from "../../homeSidebar";
import { unobserveHomeSidebar } from "../../homeSidebar";
import { unobserveHome } from "./toggleAnimationType";
import { worksInfiniteSlider } from "../../worksInfiniteSlider";
import { worksInfiniteSliderSp } from "../../worksInfiniteSliderSp";

export const homePageBeforeLeave = () => {
  // mv-navがページ遷移後に透明になる
  const nav = $("#mv-nav");
  const tl = gsap.timeline();
  tl.to(nav, {
    opacity: 0,
    duration: 0.5,
    delay: 0.8,
    ease: "power2.in",
  });

  unobserveHome();

  // 実績の無限スライダー
  if (isMobile()) {
    worksInfiniteSliderSp.destroy();
  } else {
    worksInfiniteSlider.destroy();
  }

  // MVのワンスクロールを削除（SPは除外）
  if (!isMobile()) {
    mvScroll.destroy();
  }

  // サイドバーのスクロール連動イベントを解除
  $(window).off("scroll", handleScroll);
  // unobserveHomeSidebar();
};
