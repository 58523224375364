import $ from "jquery";

export const accordion = () => {
  if ($(".js-accordion").length !== 0) {
    const triggers = $(".js-accordion-trigger");
    $(".js-accordion-content").hide();
    $(".js-accordion-content").eq(0).show();
    $(".js-accordion-content").eq(0).parent().addClass("is-open");

    triggers.on("click", function () {
      $(this).toggleClass("is-open");
      $(this).find(".js-accordion-content").slideToggle();
    });
  }
};
