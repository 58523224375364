const mvPlaceholder = document.getElementById("mv-placeholder");

const io = new IntersectionObserver(
  function (entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        document.body.setAttribute("data-animation-type", "scale");
      } else {
        document.body.setAttribute("data-animation-type", "fade");
      }
    });
  },
  {
    rootMargin: "-1px 0px",
  }
);

// observe メソッドをエクスポートする
export const observeHome = () => {
  io.observe(mvPlaceholder);
};

// unobserve メソッドをエクスポートする
export const unobserveHome = () => {
  io.unobserve(mvPlaceholder);
};
