import $ from "jquery";

export const anchorScroll = () => {
  const urlHash = location.hash;
  const headerHeight = $("#header").innerHeight();

  if (urlHash) {
    $("body,html").stop().scrollTop(0);
    setTimeout(function () {
      const target = $(urlHash);
      const position = target.offset().top - headerHeight;
      $("body,html").stop().animate({ scrollTop: position }, 500);
    }, 500);
  }

  $('a[href^="#"]').on("click", function () {
    const href = $(this).attr("href");
    const target = $(href);
    const position = target.offset().top - headerHeight;
    $("body,html").stop().animate({ scrollTop: position }, 500);
  });
};
