import $ from "jquery";
import { pageTransition } from "./modules/pageTransition";
import { loading } from "./modules/loading";
import { reloadOnBreakpoint } from "./modules/reloadOnBreakpoint";
import { showAddressBarInMobile } from "./modules/showAddressBarInMobile";
import { anchorScroll } from "./modules/anchorScroll";
import { accordion } from "./modules/accordion";
import { setAnimationType } from "./modules/setAnimationType";
import { handleSidebarClick } from "./modules/homeSidebar";

$(function () {
  loading();
  reloadOnBreakpoint();
  showAddressBarInMobile();
  anchorScroll();
  accordion();
  handleSidebarClick();
});

document.addEventListener("DOMContentLoaded", pageTransition);
