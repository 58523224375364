import barba from "@barba/core";
import { anchorScroll } from "../anchorScroll";
import { accordion } from "../accordion";
import { reloadOnBreakpoint } from "../reloadOnBreakpoint";
import { showAddressBarInMobile } from "../showAddressBarInMobile";

export const barbaBeforeEnter = () => {
  barba.hooks.beforeEnter((data) => {
    // 初期化
    anchorScroll();
    accordion();
    reloadOnBreakpoint();
    showAddressBarInMobile();

    document.body.classList.add("is-transitioning");

    // setTimeout(() => {
    //   window.scrollTo({
    //     top: 0,
    //     left: 0,
    //     behavior: "smooth",
    //   });
    // }, 100);
  });
};
