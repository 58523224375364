import { Splide } from "@splidejs/splide";

export const interviewSlider = () => {
  if (document.querySelector(".js-interview-slider") !== null) {
    const splide = new Splide(".js-interview-slider", {
      type: "loop",
      gap: "3rem",
      perMove: 1,
      perPage: 3,
      pagination: false,

      breakpoints: {
        768: {
          // perPage: 1,
          fixedWidth: "80%",
          focus: "center",
          gap: "1.6rem",
        },
      },
    });

    splide.mount();
  }
};
