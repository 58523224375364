import $ from "jquery";

export const mvNav = () => {
  const mediaQuery = window.matchMedia("(min-width: 769px)");

  $(".js-hov-trigger").on("mouseover", (e) => {
    const targetID = $(e.currentTarget).data("target");
    const state = $(e.currentTarget).data("targetstate");

    $(`.js-hov[data-id="${targetID}"]`).attr("data-state", state);
  });

  $(".js-hov").each((i, el) => {
    const dataId = $(el).data("id");
    $(el).on("mouseout", (e) => {
      $(el).attr("data-state", null);
    });
  });
};
