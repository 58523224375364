import $ from "jquery";

export const selectPlaceholder = () => {
  const select = $(".js-select-placeholder").find("select");
  select.addClass("is-empty");

  select.on("change", function () {
    if ($(this).val() !== "") {
      $(this).removeClass("is-empty");
    } else {
      $(this).addClass("is-empty");
    }
  });
};
