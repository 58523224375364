import { homeSidebarHover } from "../../homeSidebarHover";
import { mvNav } from "../../mvNav";
import { playVideo } from "./playVideo";
import { handleScroll } from "../../homeSidebar";
import { observeHomeSidebar } from "../../homeSidebar";
import { mvScroll } from "../../mvScroll";
import { isMobile } from "../../isMobile";
import $ from "jquery";
import { observeHome } from "./toggleAnimationType";
import ScrollTrigger from "gsap/ScrollTrigger";
import { worksInfiniteSlider } from "../../worksInfiniteSlider";
import { handleSidebarClick } from "../../homeSidebar";
import { worksInfiniteSliderSp } from "../../worksInfiniteSliderSp";

export const homePageAfterEnter = (data) => {
  mvNav();
  homeSidebarHover();
  handleSidebarClick();
  playVideo();
  observeHome();

  // サイドバーのスクロール連動イベントを登録
  $(window).on("scroll", handleScroll);

  // 実績の無限スライダー
  if (isMobile()) {
    worksInfiniteSliderSp.init();
  } else {
    worksInfiniteSlider.init();
  }

  // MVのワンスクロールを初期化（SPでは無効にする）
  if (!isMobile()) {
    mvScroll.init();

    // たまにページバックすると、ワンスクロール（下方向だけ）が動かないのを解決
    setTimeout(() => {
      ScrollTrigger.refresh();
    }, 100);
  }
};
