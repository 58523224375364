import $ from "jquery";
import barba from "@barba/core";
import gsap from "gsap";
import barbaPrefetch from "@barba/prefetch";
import { contactPage } from "./pages/contact/contactPage";
import { careerPage } from "./pages/career/careerPage";
import { companyPage } from "./pages/company/companyPage";
import { homePageAfterEnter } from "./pages/home/homePageAfterEnter";
import { homePageBeforeEnter } from "./pages/home/homePageBeforeEnter";
import { homePageBeforeLeave } from "./pages/home/homePageBeforeLeave";
import { barbaBeforeEnter } from "./barba/barbaBeforeEnter";
import { barbaAfterLeave } from "./barba/barbaAfterLeave";
import { barbaAfter } from "./barba/barbaAfter";
import { barbaInitialLoad } from "./barba/barbaInitialLoad";
import { homePageAfter } from "./pages/home/homePageAfter";
import { barbaBeforeLeave } from "./barba/barbaBeforeLeave";
import { barbaAfterEnter } from "./barba/barbaAfterEnter";
import { corePage } from "./pages/core/corePage";
import { clipTextScroll } from "./pages/core/clipTextScroll";

export const pageTransition = () => {
  // 初回ロードがhomeではなく下層ページの時にmv-navを消す
  barbaInitialLoad();

  // prefetch
  barba.use(barbaPrefetch, {
    timeout: 5000,
    limit: 0,
  });

  barba.init({
    debug: true,
    views: [
      {
        namespace: "home",
        beforeEnter() {
          homePageBeforeEnter();
        },
        afterEnter(data) {
          homePageAfterEnter(data);
        },
        beforeLeave() {
          homePageBeforeLeave();
        },
        after() {
          homePageAfter();
        },
      },
      {
        namespace: "company",
        beforeEnter() {
          companyPage();
        },
      },
      {
        namespace: "career",
        beforeEnter() {
          careerPage();
        },
      },
      {
        namespace: "core",
        beforeEnter() {
          corePage();
        },
        beforeLeave() {
          clipTextScroll.destroy();
        },
      },
      {
        namespace: "contact",
        beforeEnter() {
          contactPage();
        },
      },
    ],
  });

  barbaBeforeLeave();

  barbaAfterLeave();

  barbaBeforeEnter();

  barbaAfterEnter();

  barbaAfter();
};
