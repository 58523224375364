import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export const worksInfiniteSliderSp = {
  ctx: null,
  init() {
    this.ctx = gsap.context(() => {
      const scrollEls = gsap.utils.toArray(".js-works-infinite-slider-sp");
      scrollEls.forEach((scrollEl) => {
        const targetEls = scrollEl.querySelectorAll(
          ".js-works-infinite-slider-col"
        );

        let tween = gsap.to(targetEls, {
          yPercent: -100,
          repeat: -1,
          duration: 30,
          ease: "linear",
        });
        // .totalProgress(0.5);

        gsap.set(scrollEl, { yPercent: 0 });
      });
    });
  },
  destroy() {
    this.ctx.revert();
  },
};
