import { Splide } from "@splidejs/splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";

export const careerOfficeSlider = () => {
  const splide = new Splide(".js-career-office-slider", {
    type: "loop",
    gap: "0.8rem",
    fixedWidth: "40rem",
    arrows: false,
    drag: false,
    pagination: false,
    autoScroll: {
      pauseOnHover: false,
    },
    breakpoints: {
      768: {
        fixedWidth: null,
        perPage: 1,
      },
    },
  });
  splide.mount({ AutoScroll });
};
