import $ from "jquery";
import { selectPlaceholder } from "./selectPlaceholder";

export const initContactForm7 = (next) => {
  // CF7の初期化
  const cfSelector = "div.wpcf7 > form";
  const cfForms = $(cfSelector);
  console.log(cfForms);
  if (cfForms.length) {
    $(cfSelector).each(function () {
      const $form = $(this);
      wpcf7.init($form[0]);
    });
  }

  // プラポリのテキストにリンク挿入
  $(".js-privacy-check .wpcf7-list-item-label").html(`
      <a href="https://vectorinc.co.jp/privacy" target="_blank" rel="noopener">プライバシーポリシー</a>に同意する
    `);

  // selectのプレースホルダー
  selectPlaceholder();
};
