import gsap from "gsap";
import $ from "jquery";
import { mvScroll } from "../../mvScroll";
import { isMobile } from "../../isMobile";
import ScrollTrigger from "gsap/ScrollTrigger";
import { worksSlider } from "../../worksSlider";

export const homePageBeforeEnter = () => {
  window.scrollTo(0, 0);

  // homeに入るタイミングでmv-navを不透明にする
  const nav = $("#mv-nav");
  const tl = gsap.timeline();
  tl.set(nav, {
    opacity: 1,
  });

  worksSlider();

  // MVのワンスクロールを初期化（SPでは無効にする）
  // 以下afterenterに移行した
  // 問題ないなら↓削除してよし

  // if (!isMobile()) {
  //   mvScroll.init();
  //   console.log("mvscroll init");
  //   ScrollTrigger.refresh();

  //   // たまにページバックすると、ワンスクロール（下方向だけ）が動かないのを解決
  //   setTimeout(() => {
  //     ScrollTrigger.refresh();
  //   }, 100);
  // }
};
