import $ from "jquery";
import { isMobile } from "./isMobile";

export const homeSidebarHover = () => {
  if (!isMobile()) {
    $(".js-sidebar-hover").on("mouseover", (e) => {
      $(e.currentTarget).addClass("is-active");
    });

    // ホバー解除時
    $(".js-sidebar-hover").on("mouseout", (e) => {
      $(e.currentTarget).removeClass("is-active");
    });
  }
};
